<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { toast } from 'vue-sonner'
import { Separator } from '~/components/ui/separator'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarTrigger,
} from '~/components/ui/sidebar'

const { staticText } = useText()
const appConfig = useAppConfig()
const baseStorageUrl = appConfig.storage.url
const storageBucket = appConfig.storage.bucket

definePageMeta({
  layout: 'v2',
  layoutTransition: {
    appear: true,
    css: true,
    enterFromClass: 'opacity-0 blur-3xl',
    enterActiveClass: 'transition duration-300',
    leaveToClass: 'opacity-0 blur-3xl',
    leaveActiveClass: 'transition duration-300',
    mode: 'out-in',
  },
})

defineOgImageComponent('OgImagePage', {
  title: 'CrossArea',
})

onMounted(() => {
  toast('Welcome to CrossArea', {
    description: 'The knowledge portal for everyone',
    action: {
      label: 'Close',
      onClick: () => console.log('Close'),
    },
  })
})
</script>

<template>
  <div>
    <div class="flex-no-wrap flex flex-col overflow-x-hidden">
      fdws
    </div>
  </div>
</template>
